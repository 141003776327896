@import '../text_group_section_a/TextGroupSectionA.module'

.FirstLink
  margin-top: 2rem
  @include valueByBreakpoints((min: 1rem, max: 3rem), margin-bottom)

.Item
  @include CompactHeaderText
  color: $merino

.Oblong
  @include Shape($minWidth: 14.75rem, $maxWidth: 59.375rem)
  transform: translate(-40%, 30%)
  @include for-size(phone-only)
    transform: translate(100%, 120%)