@import '../common_css/variables.module'

.OuterContainer
  margin: 3rem 0rem 6rem 0rem
  @include for-size(phone-only)
    margin: 8rem 0rem 14rem 0rem

.Container
  display: flex
  flex-direction: column
  align-items: center

.Title
  @include SmallHeaderText
  color: $gulfBlue

.SubTitle
  @include DescriptionTextSmall
  color: $gulfBlue
  margin-bottom: 2rem

.Envelope
  @include Shape($minWidth: 3.5rem, $maxWidth: 6.5rem)
  left: 78%
  bottom: 40%
  @include for-size(phone-only)
    left: 50%
    bottom: 24%
    transform: translate(-50%, 0%)

.Link
  width: unset
  @include for-size(phone-only)
    width: 100%
