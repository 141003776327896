@import "../../common_css/variables.module.sass"

/* ---------------- Button ---------------- */

.Button
  margin-top: 2.5rem

/* ---------------- Oblong ---------------- */

.Oblong
  @include Shape($minWidth: 28rem, $maxWidth: 60rem)
  left: 74%
  top: 0%
  @include for-size(phone-only)
    left: 50%
    top: -12%
    transform: translate(0%, 28%)

/* ---------------- Phone ---------------- */

.Phone
  @include Shape($minWidth: 3.5rem, $maxWidth: 7.875rem)
  left: 78%
  bottom: 10%
  @include for-size(phone-only)
    left: unset
    right: 74%
    bottom: 18%

/* ---------------- TextContainer ---------------- */

.TextContainer
  grid-column: span 8
  @include for-size(phone-only)
    grid-column: span 12