@import "../../common_css/variables.module.sass"

/* ---------------- IconTextGroup ---------------- */

.IconTextGroup
  display: flex
  flex-direction: column
  align-items: center

/* ---------------- Header ---------------- */

.Header
  @include SerenityBold
  @include valueByBreakpoints((min: 1.875rem, max: 2.25rem), font-size)
  @include valueByBreakpoints((min: 2.25rem, max: 2.75rem), line-height)
  letter-spacing: 0.02rem
  text-align: center
  color: $gulfBlue
  margin-top: 1rem

/* ---------------- Description ---------------- */

.Description
  @include SerenityLight
  @include valueByBreakpoints((min: 1.25rem, max: 1.5rem), font-size)
  @include valueByBreakpoints((min: 1.5rem, max: 1.8125rem), line-height)
  text-align: center
  color: $gulfBlue
  margin-top: 1rem