@import "../../common_css/variables.module.sass"

/* ---------------- Button ---------------- */

.Button
  margin-top: 2.5rem
  @include for-size(phone-only)
    margin-top: 2.5rem

/* ---------------- Shape ---------------- */

.Shape
  position: absolute
  right: 0rem
  bottom: 0rem
  @include for-size(phone-only)
    width: 8rem
    transform: translate(-50%, -170%)

/* ---------------- Bicycle ---------------- */

.Bicycle
  @include Shape($minWidth: 14.5rem, $maxWidth: 22.5rem)
  right: 10%
  bottom: 16%
  @include for-size(phone-only)
    right: 40%
    transform: translate(50%, 0%)

/* ---------------- TextContainer ---------------- */

.TextContainer
  grid-column: span 12