@import '../../common_css/variables.module'

@mixin Link($minFontSize, $maxFontSize, $minLineHeight, $maxLineHeight)
  @include Button
  @include SerenityLight
  @include valueByBreakpoints((min: $minFontSize, max: $maxFontSize), font-size)
  @include valueByBreakpoints((min: $minLineHeight, max: $maxLineHeight), line-height)
  display: flex
  align-items: center

.LinkSmall
  @include Link(2rem, 4.5rem, 4rem, 6rem)

.LinkMedium
  @include Link(2rem, 4.5rem, 4rem, 6rem)

.LinkLarge
  @include Link(2rem, 4.5rem, 4rem, 6rem)

@mixin AnchorMixin($color)
  text-decoration: none
  color: $color
  border: 0px solid $color
  @include valueByBreakpoints((min: 0.09375rem, max: 0.1875rem), border-bottom-width)
  border-bottom-color: $color

.AnchorLight
  @include AnchorMixin($merino)

.AnchorDark
  @include AnchorMixin($gulfBlue)

.Image
  @include Shape($minWidth: 2.3rem, $maxWidth: 4rem)
  position: unset
  @include valueByBreakpoints((min: 0.5rem, max: 2rem), margin-right)