@import "../../common_css/variables.module.sass"

/* ---------------- Container ---------------- */

.Container
  display: flex
  flex-direction: column
  grid-column: 6 / -1
  @include for-size(phone-only)
    grid-column: span 12

/* ---------------- Header ---------------- */

@mixin Header
  @include SerenityExtraLight
  color: $nero
  font-size: 12.5rem
  margin: 0rem
  @include for-size(phone-only)
    font-size: 5.625rem
    line-height: 4.375rem
    text-align: unset
    margin: 0rem

.HeaderDesktop
  @include Header
  text-align: left
  @include for-size(phone-only)
    display: none

.HeaderMobile
  @include Header
  display: none
  text-align: right
  @include for-size(phone-only)
    display: unset

/* ---------------- Description ---------------- */

.Description
  @include SerenityLight
  color: $nero
  font-size: 2.25rem
  margin-top: 0.375rem
  margin-right: 28%
  @include for-size(phone-only)
    font-size: 1.5rem
    line-height: 1.8125rem
    margin-top: 1rem
    margin-right: 6%

/* ---------------- Button ---------------- */

.Button
  margin-top: 2.5rem

/* ---------------- Fun ---------------- */

.Fun
  @include Shape($minWidth: 12rem, $maxWidth: 27.125rem)
  transform: translate(-5%, -50%)
  @include for-size(phone-only)
    left: 50%
    bottom: 0%
    transform: translate(-50%, -74%)