@import "../../common_css/variables.module.sass"

/* ---------------- Button ---------------- */

@mixin ButtonMixin
  @include SerenityBold
  font-size: 1.125rem
  color: $merino
  background-color: transparent
  border: 0.125rem solid $merino
  width: 17.375rem
  height: 4rem
  cursor: pointer
  letter-spacing: 0.15rem
  @include for-size(phone-only)
    height: 3.25rem
    width: 100%
    border: 0.125rem solid $merino
  
.Button
  @include ButtonMixin

.ButtonDark
  @include ButtonMixin
  color: $gulfBlue
  border: 0.125rem solid $gulfBlue
  @include for-size(phone-only)
    border: 0.125rem solid $gulfBlue