@import "../../common_css/variables.module.sass"

/* ---------------- LargeDescription ---------------- */

.LargeDescription

/* ---------------- Text ---------------- */

@mixin Text
  text-align: center
  @include for-size(phone-only)
    text-align: left

@mixin NormalText
  @include Text
  @include DescriptionTextNormal

.TextDark
  @include NormalText
  color: $darkTextColor

.TextLight
  @include NormalText
  color: $lightTextColor

@mixin NormalItalicizedText
  @include Text
  @include ItalicizedDescriptionTextNormal

.ItalicizedTextDark
  @include NormalItalicizedText
  color: $darkTextColor

.ItalicizedTextLight
  @include NormalItalicizedText
  color: $lightTextColor

/* ---------------- EmphasizedText ---------------- */

@mixin EmphasizedText
  @include Text
  @include EmphasizedDescriptionText

.EmphasizedTextDark
  @include EmphasizedText
  color: $darkTextColor

.EmphasizedTextLight
  @include EmphasizedText
  color: $lightTextColor

@mixin EmphasizedItalicizedText
  @include Text
  @include ItalicizedEmphasizedDescriptionText

.EmphasizedItalicizedTextDark
  @include EmphasizedItalicizedText
  color: $darkTextColor

.EmphasizedItalicizedTextLight
  @include EmphasizedItalicizedText
  color: $lightTextColor