@import '../../common_css/variables.module'

@mixin Spacer($height)
  display: none
  @include for-size(phone-only)
    display: flex
    height: $height
  
.MobileSpacerSmall
  @include Spacer(5rem)

.MobileSpacerMedium
  @include Spacer(10rem)

.MobileSpacerLarge
  @include Spacer(15rem)