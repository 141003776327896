@import '../../common_css/variables.module'

.Christie
  @include Shape($minWidth: 10rem, $maxWidth: 30rem)
  transform: translate(150%, -30%)
  @include for-size(phone-only)
    transform: translate(50%, -30%)

.TextContainer
  grid-column: 2 / span 5
  @include for-size(phone-only)
    grid-column: span 12
