@import "../common_css/variables.module.sass"

/* ---------------- NavBarContainer ---------------- */

.NavBarContainer
  display: flex
  flex-direction: column
  position: relative

/* ---------------- Content ---------------- */

$navBarVerticalMargin: 1.25rem

@mixin ContentMixin
  display: flex
  flex-direction: row
  justify-content: space-between
  @include for-size(phone-only)
    flex-direction: column

.Content
  @include ContentMixin

.ContentExpanded
  @include ContentMixin

/* ---------------- MobileNavContainer ---------------- */

.MobileNavContainer
  margin: 2rem 0rem
  @include for-size(phone-only)
    display: flex
    justify-content: space-between

/* ---------------- NavBarItemsContainer ---------------- */

@mixin NavBarItemsContainerMixin
  display: flex
  justify-content: space-between
  align-items: center

.NavBarItemsContainer
  @include NavBarItemsContainerMixin
  @include for-size(phone-only)
    display: none

.NavBarItemsContainerExpanded
  @include NavBarItemsContainerMixin
  @include for-size(phone-only)
    flex-direction: column
    align-items: flex-end
    margin-bottom: 2.5rem

/* ---------------- NavBarItem ---------------- */

@mixin NavBarItem
  @include SerenityMedium
  font-size: 1rem
  letter-spacing: 0.075rem
  cursor: pointer
  text-decoration: none
  @include valueByBreakpoints((min: 2rem, max: 6rem), margin-left)
  &:first-child
    margin-left: 0rem
  @include for-size(phone-only)
    margin-left: unset
    margin-top: 3rem
    &:first-child
      margin-top: 0rem

.NavBarItemLight
  @include NavBarItem
  color: $merino

.NavBarItemDark
  @include NavBarItem
  color: $gulfBlue

/* ---------------- NavBarLine ---------------- */

@mixin NavBarLine
  position: absolute
  height: 0.0625rem
  opacity: 0.15
  width: 100%
  bottom: 0%

.NavBarLineDark
  @include NavBarLine
  background-color: $gulfBlue

.NavBarLineLight
  @include NavBarLine
  background-color: $mostWhite

/* ---------------- NavBarLogo ---------------- */

.NavBarLogo
  

/* ---------------- ExpandCollapseIcon ---------------- */

.ExpandCollapseIcon
  display: none
  cursor: pointer
  @include for-size(phone-only)
    display: unset