@import "./breakpoints.module.sass"

@function valueFromRange($range, $intervalMultiplier)
  $min: map-get($range, min)
  $max: map-get($range, max)
  $interval: abs($max - $min) / 5
  @return $min + ($interval * $intervalMultiplier)

@mixin valueByBreakpoints($minMaxMap, $property)
  #{$property}: valueFromRange($range: $minMaxMap, $intervalMultiplier: 5)
  @include for-size($size: phone-only)
    #{$property}: valueFromRange($range: $minMaxMap, $intervalMultiplier: 0)
  @include for-size($size: tablet-portrait-up)
    #{$property}: valueFromRange($range: $minMaxMap, $intervalMultiplier: 1)
  @include for-size($size: tablet-landscape-up)
    #{$property}: valueFromRange($range: $minMaxMap, $intervalMultiplier: 2)
  @include for-size($size: desktop-up)
    #{$property}: valueFromRange($range: $minMaxMap, $intervalMultiplier: 3)
  @include for-size($size: big-desktop-up)
    #{$property}: valueFromRange($range: $minMaxMap, $intervalMultiplier: 4)

@mixin SerenityExtraLight
  font-family: serenity, sans-serif
  font-style: normal
  font-weight: 200

@mixin SerenityLight
  font-family: serenity, sans-serif
  font-style: normal
  font-weight: 300

@mixin SerenityMedium
  font-family: serenity, sans-serif
  font-style: normal
  font-weight: 500

@mixin SerenityBold
  font-family: serenity, sans-serif
  font-style: normal
  font-weight: 700

@mixin CompactHeaderText
  @include SerenityExtraLight
  @include valueByBreakpoints($minMaxMap: (min: 4.5rem, max: 12.5rem), $property: font-size)
  @include valueByBreakpoints($minMaxMap: (min: 4.375rem, max: 9.375rem), $property: line-height)

@mixin HeaderText
  @include SerenityExtraLight
  @include valueByBreakpoints($minMaxMap: (min: 4.5rem, max: 12.5rem), $property: font-size)
  @include valueByBreakpoints($minMaxMap: (min: 4.375rem, max: 10.625rem), $property: line-height)

@mixin MediumHeaderText
  @include SerenityExtraLight
  @include valueByBreakpoints($minMaxMap: (min: 4rem, max: 9.375rem), $property: font-size)
  @include valueByBreakpoints($minMaxMap: (min: 4rem, max: 8.125rem), $property: line-height)

@mixin SmallHeaderText
  @include SerenityExtraLight
  @include valueByBreakpoints($minMaxMap: (min: 4rem, max: 6.25rem), $property: font-size)
  @include valueByBreakpoints($minMaxMap: (min: 4rem, max: 6.875rem), $property: line-height)

@mixin DescriptionTextSize
  @include valueByBreakpoints($minMaxMap: (min: 2.25rem, max: 4.5rem), $property: font-size)
  @include valueByBreakpoints($minMaxMap: (min: 2.75rem, max: 5.375rem), $property: line-height)

@mixin DescriptionTextNormal
  @include DescriptionTextSize
  @include SerenityLight

@mixin DescriptionTextSizeSmall
  @include valueByBreakpoints($minMaxMap: (min: 1.5rem, max: 1.875rem), $property: font-size)
  @include valueByBreakpoints($minMaxMap: (min: 1.8125rem, max: 2.25rem), $property: line-height)

@mixin DescriptionTextSmall
  @include DescriptionTextSizeSmall
  @include SerenityLight

@mixin EmphasizedDescriptionText
  @include DescriptionTextSize
  @include SerenityBold

@mixin ItalicizedDescriptionTextNormal
  @include DescriptionTextSize
  @include SerenityLight
  font-style: italic

@mixin ItalicizedEmphasizedDescriptionText
  @include DescriptionTextSize
  @include SerenityBold
  font-style: italic

@mixin SubtitleTextSize
  @include valueByBreakpoints((min: 0.875rem, max: 1.5rem), font-size)
  @include valueByBreakpoints((min: 1.0625rem, max: 1.8125rem), font-size)
  letter-spacing: 0.1rem

@mixin SubtitleText
  @include SerenityBold
  @include SubtitleTextSize