@import '../../common_css/variables.module'

.FirstLink
  margin-top: 2rem
  @include valueByBreakpoints((min: 1rem, max: 3rem), margin-bottom)

.Logo
  @include Shape($minWidth: 12.75rem, $maxWidth: 22.375rem)
  right: 10%
  transform: translate(0%, 90%)
  @include for-size(phone-only)
    right: 40%
    transform: translate(50%, 220%)