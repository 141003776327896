@import '../../common_css/variables.module'

.Cali
  @include Shape($minWidth: 10rem, $maxWidth: 30rem)
  transform: translate(160%, -30%)
  @include for-size(phone-only)
    transform: translate(50%, -30%)

.TextContainer
  grid-column: 1 / span 5
  @include for-size(phone-only)
    grid-column: span 12

.Curve
  @include Shape($minWidth: 10rem, $maxWidth: 15.625rem)
  left: 50%
  transform: translate(-50%, 100%)