@import '../../common_css/variables.module'

.Shannon
  @include Shape($minWidth: 10rem, $maxWidth: 24rem)
  transform: translate(230%, -30%)
  @include for-size(phone-only)
    transform: translate(50%, -30%)

.TextContainer
  grid-column: 2 / span 6
  @include for-size(phone-only)
    grid-column: span 12