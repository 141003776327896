@import '../../common_css/variables.module'

.Container
  margin-top: 2rem
  @include for-size(phone-only)
    margin-top: 7rem

.Oblong
  @include Shape($minWidth: 20rem, $maxWidth: 50rem)
  transform: translate(140%, -70%)
  @include for-size(phone-only)
    transform: translate(120%, -90%)

.OblongB
  @include Shape($minWidth: 2.875rem, $maxWidth: 17.5rem)
  right: 30%
  transform: translate(0%, 80%)
  @include for-size(phone-only)
    right: unset
    transform: translate(300%, -160%)

.PalmTreeContainer
  @include for-size(phone-only)
    display: flex
    justify-content: center

.PalmTree  
  @include Shape($minWidth: 2.25rem, $maxWidth: 4.75rem)
  transform: translate(1100%, -300%)
  @include for-size(phone-only)
    transform: translate(0%, 50%)

.TextContainer
  grid-column: 2 / span 5
  @include for-size(phone-only)
    grid-column: span 12