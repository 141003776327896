@import "../../common_css/variables.module.sass"

.CurvyUnderline
  @include Shape($minWidth: 13.5rem, $maxWidth: 24.125rem)
  transform: translate(0%, 120%)
  @include for-size(phone-only)
    transform: translate(0%, 80%)

.ImageContainer
  display: flex
  justify-content: center