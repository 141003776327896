$minContentBreakpointWidth: 20rem
$maxContentBreakpointWidth: 90rem

@mixin for-size($size)
  @if $size == tiny
    @media (max-width: $minContentBreakpointWidth)
      @content
  @else if $size == phone-only
    @media (max-width: 37.4375rem) 
      @content
  @else if $size == tablet-portrait-up
    @media (min-width: 37.5rem) 
      @content
  @else if $size == tablet-landscape-up
    @media (min-width: 56.25rem)
      @content
  @else if $size == desktop-up
    @media (min-width: 75rem)
      @content
  @else if $size == big-desktop-up
    @media (min-width: $maxContentBreakpointWidth)
      @content