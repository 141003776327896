@import "../common_css/variables.module.sass"

/* ---------------- SectionF ---------------- */

.Footer
  display: flex
  flex-direction: column
  @include for-size(phone-only)
    justify-content: center
    align-items: center


/* ---------------- Container ---------------- */

.Container
  display: flex
  justify-content: space-between
  margin-top: 25.5rem
  margin-bottom: 1.75rem
  @include for-size(phone-only)
    margin-top: 10.875rem
    margin-bottom: 5.25rem
    flex-direction: column
    align-items: center


/* ---------------- MostIconsContainer ---------------- */

.MostIconsContainer
  display: flex
  width: 12.5rem
  cursor: pointer
  img:last-child
    margin-left: 1.5rem

  @include for-size(phone-only)
    flex-direction: column-reverse
    align-items: center
    img:first-child
      margin-top: 0.75rem
  
    img:last-child
      margin-left: unset
  


/* ---------------- ButtonsContainer ---------------- */

.ButtonsContainer
  @include SerenityLight
  display: flex
  color: $merino
  align-self: center
  cursor: default
  @include for-size(phone-only)
    margin-top: 3rem
    flex-direction: column
    align-items: center


/* ---------------- Button ---------------- */

.Button
  @include Button
  @include SerenityLight
  font-size: 1rem
  letter-spacing: 0.1rem
  color: $merino
  margin: 0rem 0.5rem
  @include for-size(phone-only)
    @include SerenityMedium
    font-size: 0.875rem
    line-height: 2.5rem


.Button:focus
  outline: none

/* ---------------- InstagramIcon ---------------- */

.InstagramIcon
  display: flex
  cursor: pointer
  width: 12.5rem
  justify-content: flex-end
  @include for-size(phone-only)
    margin-top: 2rem
    width: unset


/* ---------------- DividerLine ---------------- */

.DividerLine
  @include for-size(phone-only)
    display: none
