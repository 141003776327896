@import "../../common_css/variables.module.sass"

@mixin Section
  position: relative
  @include for-size(big-desktop-up)
    display: flex
    flex-direction: column
    align-items: center

.SectionLight
  @include Section
  background-color: $mostWhite

.SectionMediumLight
  @include Section
  background-color: $merino

.SectionMediumDark
  @include Section
  background-color: $shilo

.SectionDark
  @include Section
  background-color: $gulfBlue

.SectionVeryDark
  @include Section
  background-color: $midnightExpress

.Content
  @include SectionContent

.ContentOnlySideMargins
  @include SectionContentHorizontal