@import "./breakpoints.module.sass"

$sideMarginLarge: 12.5rem
$sideMarginMedium: 9.0rem
$sideMarginSmall: 6rem
$sideMarginExtraSmall: 2rem
$sideMarginMin: 1.125rem

$verticalMarginLarge: 17.5rem
$verticalMarginMedium: 15rem
$verticalMarginSmall: 12.5rem
$verticalMarginExtraSmall: 10rem
$verticalMarginMin: 7.5rem

@mixin SectionContentVertical
  margin-top: unset
  margin-bottom: unset
  @include for-size(phone-only)
    margin-top: $verticalMarginMin
    margin-bottom: $verticalMarginMin
  @include for-size(tablet-portrait-up)
    margin-top: $verticalMarginExtraSmall
    margin-bottom: $verticalMarginExtraSmall
  @include for-size(tablet-landscape-up)
    margin-top: $verticalMarginSmall
    margin-bottom: $verticalMarginSmall
  @include for-size(desktop-up)
    margin-top: $verticalMarginMedium
    margin-bottom: $verticalMarginMedium
  @include for-size(big-desktop-up)
    margin-top: $verticalMarginLarge
    margin-bottom: $verticalMarginLarge

@mixin SectionContentHorizontal
  margin-left: unset
  margin-right: unset
  @include for-size(tiny)
    width: $minContentBreakpointWidth - ($sideMarginMin * 2)
  @include for-size(phone-only)
    margin-left: $sideMarginMin
    margin-right: $sideMarginMin
  @include for-size(tablet-portrait-up)
    margin-left: $sideMarginExtraSmall
    margin-right: $sideMarginExtraSmall
  @include for-size(tablet-landscape-up)
    margin-left: $sideMarginSmall
    margin-right: $sideMarginSmall
  @include for-size(desktop-up)
    margin-left: $sideMarginLarge
    margin-right: $sideMarginLarge
  @include for-size(big-desktop-up)
    width: $maxContentBreakpointWidth - ($sideMarginLarge * 2)

@mixin SectionContent
  @include SectionContentVertical
  @include SectionContentHorizontal

@mixin Page
  overflow-x: hidden
  position: relative
  display: flex
  flex-direction: column