@import "../../common_css/variables.module.sass"

/* ---------------- Text ---------------- */

@mixin TextMixin
  margin-top: 1rem

.TextLight
  @include DescriptionTextNormal
  @include TextMixin
  color: $lightTextColor

.TextDark
  @include DescriptionTextNormal
  @include TextMixin
  color: $darkTextColor

.SmallTextLight
  @include DescriptionTextSmall
  @include TextMixin
  color: $lightTextColor

.SmallTextDark
  @include DescriptionTextSmall
  @include TextMixin
  color: $darkTextColor

/* ---------------- TitleText ---------------- */

.TitleTextLight
  @include HeaderText
  color: $lightTextColor

.TitleTextDark
  @include HeaderText
  color: $darkTextColor

.SmallTitleTextLight
  @include MediumHeaderText
  color: $lightTextColor

.SmallTitleTextDark
  @include MediumHeaderText
  color: $darkTextColor

/* ---------------- SubtitleText ---------------- */

@mixin Subtitle
  margin: 1.25rem 0rem 0.8rem 0rem

.SubtitleTextLight
  @include Subtitle
  @include SubtitleText
  color: $lightTextColor

.SubtitleTextDark
  @include Subtitle
  @include SubtitleText
  color: $darkTextColor

/* ---------------- Alignment ---------------- */

.Container
  display: flex 
  flex-direction: column

@mixin Alignment
  @include for-size(phone-only)
    align-items: unset
    text-align: unset

.Left
  @include Alignment
  align-items: flex-start
  text-align: left

.Center
  @include Alignment
  align-items: center
  text-align: center

.Right
  @include Alignment
  align-items: flex-end
  text-align: right

.Image
  @include Shape($minWidth: 2.4375rem, $maxWidth: 3.75rem)
  position: unset
  @include valueByBreakpoints((min: .5rem, max: 1.5rem), margin-bottom)