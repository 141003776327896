@import "../../common_css/variables.module.sass"

/* ---------------- Container ---------------- */

.Container
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  width: 100%
  grid-auto-rows: 1fr
  @include for-size(tiny)
    grid-column-gap: 1rem
    grid-template-columns: 1fr
    & > .Item:last-child
      grid-column: unset
      width: unset
      justify-self: unset
  @include for-size(phone-only)
    grid-column-gap: 1rem
    grid-template-columns: 1fr
    & > .Item:last-child
      grid-column: unset
      width: unset
      justify-self: unset
  @include for-size(tablet-portrait-up)
    grid-column-gap: 2rem
    grid-template-columns: 1fr 1fr
    & > .Item:last-child
      grid-column: span 2
      width: calc(50% - 2rem / 2)
      justify-self: center
  @include for-size(tablet-landscape-up)
    grid-column-gap: 2rem
    grid-template-columns: 1fr 1fr
    & > .Item:last-child
      grid-column: span 2
      width: calc(50% - 2rem / 2)
      justify-self: center
  @include for-size(desktop-up)
    grid-template-columns: 1fr 1fr 1fr
    grid-column-gap: 4rem
    & > .Item:last-child
      grid-column: unset
      width: unset
      justify-self: unset
  @include for-size(big-desktop-up)
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-column-gap: 4rem
    & > .Item:last-child
      grid-column: unset
      width: unset
      justify-self: unset


/* ---------------- Header ---------------- */

.Header
  @include SerenityBold
  @include valueByBreakpoints((min: 2.5rem, max: 4.5rem), font-size)
  @include valueByBreakpoints((min: 3rem, max: 5.375rem), line-height)
  color: $gulfBlue
  text-align: center

/* ---------------- Item ---------------- */

.Item

/* ---------------- IconContainer ---------------- */

.IconContainer
  display: flex
  flex-direction: row
  align-items: flex-end
  justify-content: center
  @include valueByBreakpoints((min: 8rem, max: 12.5rem), height)

/* ---------------- Icon ---------------- */

.Icon

/* ---------------- Button ---------------- */

.Button
  text-align: center
  margin-top: 3rem

.Oblong
  @include Shape($minWidth: 33.75rem, $maxWidth: 100rem)
  right: 0%
  transform: translate(-90%, -70%)
  @include for-size(phone-only)
    transform: translate(-40%, -70%)

.OblongB
  @include Shape($minWidth: 33.75rem, $maxWidth: 100rem)
  top: 30%
  left: 80%
  @include for-size(phone-only)
    display: none